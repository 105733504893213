import service from '@/services'

export default {
  methods: {
    async clearExportedFile(filename) {
      try {
        const config = {
          url: '/clear-export',
          method: 'get',
          params: {
            filename,
          },
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }
        const response = await service.sendRequest(config)

        return Promise.resolve(response.data)
      } catch (e) {
        if (e.response && e.response.data) {
          const blob = e.response.data
          const text = await blob.text()
          const errorJson = JSON.parse(text)

          return Promise.reject(errorJson)
        }
        return Promise.reject(e)
      }
    },
    async printCollection(params) {
      try {
        const config = {
          url: params.type_export == 'pdf' ? '/cetak-collection' : '/export-collection',
          method: 'get',
          params,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }

        if (params.type_export == 'pdf') {
          config.responseType = 'blob'
        }
        const response = await service.sendRequest(config)

        return Promise.resolve(response.data)
      } catch (e) {
        if (e.response && e.response.data) {
          const blob = e.response.data
          const text = await blob.text()
          const errorJson = JSON.parse(text)

          return Promise.reject(errorJson)
        }
        return Promise.reject(e)
      }
    },
    setCurrentHarga(barang, konsumen) {
      if (barang.harga_jual) {
        const findHarga = barang.harga_jual.find(harga => harga.tipe_konsumen_id == konsumen.tipe_konsumen)
        return findHarga ? findHarga.harga : 0
      }
      return 0
    },
    async getStockMobil(barangs) {
      if (this.myGudang) {
        const barangsId = barangs.map(barang => barang.id)
        const stocks = await this.$store.dispatch('penyimpanan/getData', {
          gudang_id: this.myGudang.id,
          barang_id: barangsId.join(','),
        })
        barangs.map(barang => {
          barang.stocks = stocks.filter(stock => stock.barang && stock.barang.id == barang.id)
        })
      }

      return barangs
    },
    async getReturPayload(penjualan) {
      const nowDate = new Date()
      const now = `${nowDate.getFullYear()}-${nowDate.getMonth()}-${nowDate.getDate()}`
      let payload = {}
      if (!penjualan.sales || !penjualan.konsumen) {
        return false
      }

      // get gudang sales
      const gudangSales = await this.$store.dispatch('gudang/getData', {
        jenis: 2,
        karyawan_id: penjualan.sales.id,
      })

      if (gudangSales) {
        payload = {
          id_sales: penjualan.sales ? penjualan.sales.id : null,
          id_konsumen: penjualan.konsumen ? penjualan.konsumen.id : null,
          id_penjualan: penjualan.id,
          keterangan: '-',
          id_gudang_m: gudangSales[0].id,
          tanggal: now,
        }
      }

      return payload
    },
    async getRincianPayload(retur, penjualan) {
      const payloads = []
      penjualan.rincian.map(rincian => {
        if (rincian.barang) {
          payloads.push({
            id_retur_konsumen: retur.id,
            id_barang: rincian.barang.id,
            qty: rincian.qty,
            harga_jual: rincian.harga_jual,
          })
        }
      })

      return payloads
    },
    setCurrentTotalBayar(payloadBarang) {
      return payloadBarang.reduce((total, barang) => total += (parseInt(barang.qty) * parseInt(barang.harga_jual)), 0)
    },
  },
}
